import { Injectable } from '@angular/core';
import { Course } from '../models/course.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {
  courses: Course[];
//  url = 'http://localhost:3500';
//  url = 'http://localhost:3000';
//  url = 'https://palmdale-dev.online:3000';
  url = 'https://api.palmdale-dev.online:3000';
  h1 = new HttpHeaders().set('accept', 'application/json');
  h2 = new HttpHeaders()
    .set('accept', 'application/json')
    .set('content-type', 'application/json');

/* joy save for now, may change and use this
    private _courseLoc = new BehaviorSubject<Course[]>([]);

    get courseLoc() {
      const localCourses = localStorage.getItem('courses');
    // tslint:disable-next-line: no-angle-bracket-type-assertion
    const cloc = <Course[]> JSON.parse(localCourses);
      return this._courseLoc.asObservable();
    }
    */


  constructor(private http: HttpClient) {}

  getCourses(): Observable<Course[]> {
    const headers = this.h1;
    return this.http
      .get<Course[]>(`${this.url}/api/courses`, { headers })
      .pipe(
        map((results) => {
          console.log('Using server data!');
          this.setLocalCourses(results); // Reset local data
          return results;
        }),
        catchError<any, Observable<Course[]>>((err) => {
          // If the api fails to retrieve data from the server use local data
          console.log('Using local data!');
          return of(this.getLocalCourses());
        })
      );
  }

  setLocalCourses(courses: Course[]) {
    // TODO - finish this
    //  Before resetting the local course's data, see if the local data has riders the server doesn't
    //    if so, add them to the server before resetting local data
    //  Note: that still working out when course will added to DB (i.e. at time of Contract signing) and
    //   once set-up this app needs to just get it and keep it identified, so needs to be in local storage
    const localCourses = this.getLocalCourses();
    localStorage.setItem('courses', JSON.stringify(courses));
    return;
  }

  getLocalCourses(): Course[] {
    const localCourses = localStorage.getItem('appcourse');
    // tslint:disable-next-line: no-angle-bracket-type-assertion
    const local = <Course[]> JSON.parse(localCourses);
    return local;
  }

  getCourseById(id: string): Observable<Course> {
    const headers = this.h1;
    return this.http
      .get<Course>(`${this.url}/api/courses/${id}`, { headers })
      .pipe(
          map((result) => {
            console.log('Using Server data!', result);
            this.setLocalCourseForApp(result);    // Set this golf course in local data
            return(result);
          }),
          catchError<any, Observable<Course>>((err) => {
            // If the API fails to retrieve data from the server, user local data
            // if we get a 404 back, then course number entered was not found in our database, want to handle that
          //  if (err.message.indexOf('Not Found') > 0 ) {
          //    return of(err);
          //  } else {
              // assume no connection??
            console.log('Error from Api: ' + err.toString());
            console.log('Using local data!');
            return of(this.getLocalCourseForApp(id));
//            return of(err);
//            }
          })
      );
  }
  getLocalCourseForApp(id: string): Course {
    const localAppCourse = localStorage.getItem('appcourse');
    // tslint:disable-next-line: no-angle-bracket-type-assertion
    const localCourse = <Course> JSON.parse(localAppCourse);
    return localCourse;
  }

  getAppCourse(): Course {
    const appCourseLoc = localStorage.getItem('appcourse');
    // tslint:disable-next-line: no-angle-bracket-type-assertion
    const appCourse = <Course> JSON.parse(appCourseLoc);
    return appCourse;
  }

  setLocalCourseForApp(result: Course) {
    // TODO - finish this
    //  Only reset if Course is missing from local storage, but based on how we set a course up to use this app,
    //  would be good to see if the local data app course exists on the server and if not add it to server first,
    //  before resetting the local course's data, see if the local data has riders the server doesn't
    //  Note: still working out when course will added to DB (i.e. at time of Contract signing) and
    //   once set-up this app needs to just get it and keep it identified, so needs to be in local storage
    const localAppCourse = this.getLocalCourseForApp(result.id);
    localStorage.setItem('appcourse', JSON.stringify(result));
  }



}
